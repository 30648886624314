import Joi from 'joi';

export const escalateValidator = Joi.object({
  assignedUserId: Joi.string().required().messages({
    'string.base': 'Please select person',
    'string.empty': 'Please select person',
  }),
  highImportance: Joi.boolean().optional(),
  subject: Joi.string().optional().allow('', null),
  notes: Joi.string().optional().allow('', null),
})
  .unknown(true)
  .preferences({ abortEarly: false });
