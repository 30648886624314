import {
  ReactNode,
  RefObject,
  useCallback,
  useRef,
} from 'react';
import { Button } from 'primereact/button';

import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import {
  SurveillanceEntityStatus,
  SurveillanceModeEnum,
} from '../../Models/Enums';
import {
  DEFAULT_SEARCH_ITEMS,
  SearchRequest,
} from '../../Models/ReportsRequest';
import { ResultsResponse } from '../../Models/ReportsResponse';
import { useUpdateAuditState } from '../../Services/AuditService';
import EscalatePopup from '../EscalatePopup';
import ExportPopup, {
  ExportPopupReferenceProps,
} from '../ExportPopup/ExportPopup';
import {
  ExportButton,
  SurveillancePopupFooter,
  SurveillancePopupFooterReferenceProps,
} from '../ExportPopup/Templates';
import MarkAsReviewedPopup, {
  MarkAsReviewedPopupReferenceProps,
} from '../MarkAsReviewedPopup';
import ReturnWithCommentsPopup, {
  ReturnWithCommentsPopupReferenceProps,
} from '../ReturnWithCommentsPopup';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { eventBus } from 'server/EventBus';

import type { SelectedContentMessage } from '../RightPanel/Models';

interface ITableFooterProps {
  selectedItems: ResultsResponse[];
  clearSelection: () => void;
  recordsCount: number;
  isSelectAll: boolean;
  selectedSiblingMessages?: SelectedContentMessage[];
  toastRef?: RefObject<ToastMessageRef>;
  searchItems?: SearchRequest;
  activeWorksheetId?: string | null;
  activeWorksheetName?: string | null;
  resultsMode?: SurveillanceModeEnum;
}

const TableFooter = (props: ITableFooterProps): ReactNode => {
  const {
    selectedItems,
    clearSelection,
    recordsCount,
    isSelectAll,
    selectedSiblingMessages = [],
    toastRef,
    searchItems,
    activeWorksheetId,
    activeWorksheetName,
    resultsMode,
  } = props;

  const exportDetailsRef = useRef<ExportPopupReferenceProps | null>(null);
  const exportFooterRef = useRef<SurveillancePopupFooterReferenceProps | null>(
    null
  );
  const markAsReviewedDetailsRef =
    useRef<MarkAsReviewedPopupReferenceProps | null>(null);
  const markAsReviewedFooterRef =
    useRef<SurveillancePopupFooterReferenceProps | null>(null);
  const returnWithCommentsDetailsRef =
    useRef<ReturnWithCommentsPopupReferenceProps | null>(null);
  const returnWithCommentsFooterRef =
    useRef<SurveillancePopupFooterReferenceProps | null>(null);
  const escalateDetailsRef = useRef<MarkAsReviewedPopupReferenceProps | null>(
    null
  );
  const escalateFooterRef =
    useRef<SurveillancePopupFooterReferenceProps | null>(null);

  const { trigger: triggerUpdate } = useUpdateAuditState(activeWorksheetId);

  const closePopup = useCallback(
    (isClearSelection = false): void => {
      if (isClearSelection && resultsMode === SurveillanceModeEnum.Escalated) {
        clearSelection();
      }
      eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null);
    },
    [resultsMode, clearSelection]
  );

  const onExportClick = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      // slice first 43 symbols in order to have overall length of a header equal to 50 symbols
      header: `Export ${ activeWorksheetName?.slice(0, 43) }`,
      body: (
        <ExportPopup
          ref={exportDetailsRef}
          activeWorksheetId={activeWorksheetId || ''}
          worksheetName={activeWorksheetName || ''}
          searchParams={searchItems || DEFAULT_SEARCH_ITEMS}
          selectedItems={selectedItems}
          recordsCount={recordsCount}
          isSelectAll={isSelectAll}
          siblingMessages={selectedSiblingMessages}
        />
      ),
      footer: (
        <SurveillancePopupFooter
          ref={exportFooterRef}
          onCancel={() => closePopup()}
          onConfirm={onWorkSheetExport}
        />
      ),
      size: 'large',
    });
  };
  const onWorkSheetExport = (): void => {
    exportFooterRef.current?.setIsLoading(true);
    exportDetailsRef?.current
      ?.submit()
      .finally(() => {
        exportFooterRef.current?.setIsLoading(false);
        closePopup();
      })
      .then(params => {
        if (params?.includeAudio) {
          toastRef?.current?.replace({
            title: 'Preparing the download',
            message: 'You\'ll receive an email when the download is ready.',
          });
        }
      });
  };

  const onReturnWithComments = useCallback((): void => {
    returnWithCommentsFooterRef.current?.setIsLoading(true);
    returnWithCommentsDetailsRef?.current
      ?.submit()
      .then(() => {
        closePopup(true);
        toastRef?.current?.replace({
          title: 'Success',
          message: 'Selected items are returned with comments',
          severity: ToastSeverity.SUCCESS,
        });
      })
      .catch((e): void => {
        if (e !== 'validator') {
          // If it's not from validator
          toastRef?.current?.replace({
            title: 'Error',
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR,
          });
        }
      })
      .finally(() => returnWithCommentsFooterRef.current?.setIsLoading(false));
  }, [toastRef, closePopup]);

  const onMarkAsReviewed = useCallback((): void => {
    markAsReviewedFooterRef.current?.setIsLoading(true);
    markAsReviewedDetailsRef?.current
      ?.submit()
      .then(() => {
        closePopup(true);
        toastRef?.current?.replace({
          title: 'Success',
          message: 'Selected items are marked as reviewed',
          severity: ToastSeverity.SUCCESS,
        });
      })
      .catch((e): void => {
        if (e !== 'validator') {
          // If it's not from validator
          toastRef?.current?.replace({
            title: 'Error',
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR,
          });
        }
      })
      .finally(() => markAsReviewedFooterRef.current?.setIsLoading(false));
  }, [toastRef, closePopup]);

  const onEscalate = useCallback((): void => {
    escalateFooterRef.current?.setIsLoading(true);
    escalateDetailsRef?.current
      ?.submit()
      .then(() => {
        closePopup();
        toastRef?.current?.replace({
          title: 'Success',
          message: 'Selected items has been successfully escalated',
          severity: ToastSeverity.SUCCESS,
        });
      })
      .catch((e): void => {
        if (e !== 'validator') {
          // If it's not from validator
          toastRef?.current?.replace({
            title: 'Error',
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR,
          });
        }
      })
      .finally(() => escalateFooterRef.current?.setIsLoading(false));
  }, [toastRef, closePopup]);

  const onEscalateClick = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: 'Escalate',
      body: (
        <EscalatePopup
          ref={escalateDetailsRef}
          activeWorksheetId={activeWorksheetId || ''}
          selectedItems={selectedItems}
          siblingMessages={selectedSiblingMessages}
        />
      ),
      footer: (
        <SurveillancePopupFooter
          ref={escalateFooterRef}
          onCancel={() => closePopup()}
          onConfirm={onEscalate}
          confirmValue='Escalate'
        />
      ),
      size: 'large',
    });
  };

  const onReturnWithCommentsClick = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: 'Return with Comments',
      body: (
        <ReturnWithCommentsPopup
          ref={returnWithCommentsDetailsRef}
          activeWorksheetId={activeWorksheetId || ''}
          selectedItems={selectedItems}
        />
      ),
      footer: (
        <SurveillancePopupFooter
          ref={returnWithCommentsFooterRef}
          onCancel={() => closePopup()}
          onConfirm={onReturnWithComments}
          confirmValue='Return with Comments'
        />
      ),
      size: 'large',
    });
  };

  const onMarkAsReviewedClick = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: 'Mark as Reviewed',
      body: (
        <MarkAsReviewedPopup
          ref={markAsReviewedDetailsRef}
          activeWorksheetId={activeWorksheetId || ''}
          selectedItems={selectedItems}
        />
      ),
      footer: (
        <SurveillancePopupFooter
          ref={markAsReviewedFooterRef}
          onCancel={() => closePopup()}
          onConfirm={onMarkAsReviewed}
          confirmValue='Mark as Reviewed'
        />
      ),
      size: 'large',
    });
  };

  const onRestoreClick = (): void => {
    triggerUpdate({
      items: selectedItems.map(el => ({
        id: el.id,
        partitionKey: el.partitionKey,
      })),
      assignedUserId: null,
      status: SurveillanceEntityStatus.ActiveWithComments,
      reason: null,
      note: 'restored by user',
      worksheetId: activeWorksheetId || '',
    })
      .then((): void => {
        toastRef?.current?.replace({
          title: 'Success',
          message: 'Selected items are restored in search results',
          severity: ToastSeverity.SUCCESS,
        });
      })
      .catch((): void => {
        toastRef?.current?.replace({
          title: 'Error',
          message: 'Sorry, something has gone wrong. Please try again later.',
          severity: ToastSeverity.ERROR,
        });
      });
  };

  return (
    <footer>
      Selected: {selectedItems.length}{' '}
      {selectedItems.length > 1 ? 'items' : 'item'}
      {resultsMode === SurveillanceModeEnum.Results && (
        <>
          <ExportButton isDisabled={!recordsCount} onClick={onExportClick} />
          <Button
            size='small'
            className='surveillance-escalate'
            outlined
            onClick={onEscalateClick}
          >
            Escalate
          </Button>
          <Button
            size='small'
            className='surveillance-reviewed'
            outlined
            onClick={onMarkAsReviewedClick}
          >
            Mark as Reviewed
          </Button>
        </>
      )}
      {resultsMode === SurveillanceModeEnum.Reviewed && (
        <Button
          size='small'
          className='surveillance-reviewed'
          outlined
          onClick={onRestoreClick}
        >
          Restore
        </Button>
      )}
      {resultsMode === SurveillanceModeEnum.Escalated && (
        <>
          <Button
            size='small'
            className='surveillance-reviewed'
            outlined
            onClick={onReturnWithCommentsClick}
          >
            Return with Comments
          </Button>
          <Button
            size='small'
            className='surveillance-reviewed'
            outlined
            onClick={onMarkAsReviewedClick}
          >
            Mark as Reviewed
          </Button>
        </>
      )}
    </footer>
  );
};

export default TableFooter;
