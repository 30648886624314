import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import clsx from 'clsx';

import { IndividualSanctionResult } from 'modules/Sanctions/Models/IndividualSanctionResult';
import { useIndividualDetailsApi } from 'modules/Sanctions/Services/SanctionsAPI';

import AliasQuality from '../../Templates/AliasQuality';

import type { Detail } from 'modules/Sanctions/Models/IndividualSanctionResult';
import type { IndividualSanctionResponse } from 'modules/Sanctions/Models/SanctionedItems';

import './SanctionedIndividualDetails.scss';

interface SanctionedIndividualDetailsProps {
    selectedItem: IndividualSanctionResponse;
}

export default function SanctionedIndividualDetails (props: SanctionedIndividualDetailsProps): JSX.Element {
    const { selectedItem } = props;
    const [ details, setDetails ] = useState<Detail[] | null>(null);

    const { data } = useIndividualDetailsApi(selectedItem?.id, selectedItem?.listName);

    useEffect(() => {
        if (!data) return;

        setDetails(IndividualSanctionResult.generateDetails(data));
    }, [data]);

    if (!data || !details) {
        return <div>Please select at item from the table…</div>;
    }

    return <div className='direction--column overflow--y'>
        {details.map(({ key, value }) =>
            <div key={key} className="individual-sanction-detail--container">
                {value.length ?
                    <DataTable
                        className={clsx('grow-to-fill header--micro', {
                            'column--aka': key === 'AKA',
                            'column--pob': key === 'Place of Birth',
                            'column--address': key === 'Address'
                        })}
                        dataKey='value'
                        value={value}
                    >
                        <Column
                            header={key}
                            field='value'
                        />

                        {key === 'Address' &&
                            <Column
                                header="Postal Code"
                                field='postalCode'
                            />
                        }
                        {key === 'Address' &&
                            <Column
                                header="Country"
                                field='country'
                            />
                        }
                        {key === 'AKA' &&
                            <Column
                                header="Quality"
                                body={AliasQuality}
                            />
                        }
                        {key === 'Place of Birth' &&
                            <Column
                                field='country'
                            />
                        }
                    </DataTable>
                    : <></>
                }
            </div>
        )}
    </div>

}