import { ReactNode, useMemo } from 'react';

import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import SecondaryNavigation from 'components/SecondaryNavigation';

type NavigationItem = {
  label: string;
  path: string;
  secuirty: SecurityRights;
};

const Subnavigation = (): ReactNode => {
  const { obxuser } = useLoggedInUser();

  const items: NavigationItem[] = useMemo(
    () => [
      {
        label: 'Reports',
        path: 'results',
        secuirty: SecurityRights.Surveillance,
      },
      {
        label: 'Escalations',
        path: 'escalations',
        secuirty: SecurityRights.SurveillanceEscalations,
      },
      {
        label: 'Shipping Fixtures',
        path: 'sanctions',
        secuirty: SecurityRights.FixtureSanctionsSearch,
      },
    ],
    []
  );

  /**
   * create collection of the sub-routes that the currently logged in user
   * has access too
   **/
  const accissible = useMemo(() => {
    if (!obxuser) {
      return [];
    }
    // return items;
    return items.filter(k =>
      obxuser.assignedSecurityRights.includes(k.secuirty)
    );
  }, [obxuser, items]);

  if (accissible.length > 1) {
    return (
      <nav className='tabbed-navigation-set__container'>
        <SecondaryNavigation items={accissible} />
      </nav>
    );
  }
};

export default Subnavigation;
