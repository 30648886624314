import {
  forwardRef,
  Key,
  memo,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { clsx } from 'clsx';

import { useCargoFlows } from '../../Services/HistoricalFlows';
import { CargoMovementRequest, CargoMovementSearchResult, CargoMovementResponse, CargoMovementOrderCriteriaEnum, ConditionalFilter } from '../../Models/CargoMovements';
import CargoDataGridView from './CargoDataGridView';

import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';

// import { doubleLineFlagged, doubleLineQuantity } from '../../../../helpers/data-table/column-templates';
import {
  DoubleLineFlagged, DoubleLineSimple,
  SingleLineTruncated, ReadableDateTime,
  DoubleLineQuantity
} from 'helpers/DataTable/Templates/ColumnTemplates';
import { ServerSortHeader } from 'helpers/DataTable/Templates/Headers';

import { ServiceCallError } from 'components/Errors/ServiceCalls';

import { cargoFlowLocation } from '../../Templates/CargoFlowLocation';
import { Probability } from '../../Templates/Probability';
import { SupplementalBody } from '../../Templates/Supplemental';

import { asEncoded, uniqueId } from 'helpers/Utils/string';
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';

import Loader from 'components/Loader';

import type { VirtualScrollerProps } from 'primereact/virtualscroller';
import type F from 'types/generic-type';

import styles from './CargoDataTable.module.scss';

export type DataConfigItem = {
  field: string;
  renderer: (data: CargoMovementSearchResult & F<string>, config: ColumnBodyOptions, opts?: any) => ReactNode;
  label: string;
  labelMobile?: string;
}

export type DataConfig = {
  vessel: DataConfigItem,
  owner: DataConfigItem,
  charterer: DataConfigItem,
  category: DataConfigItem,
  quantity: DataConfigItem,
  loadPort: DataConfigItem,
  loadTime: DataConfigItem,
  dischargePort: DataConfigItem,
  dischargeTime: DataConfigItem,
  supplemental: DataConfigItem,
  probability: DataConfigItem
};

export const DATA_CONFIG: DataConfig = {
  vessel: {
    field: 'vesselName,vesselImoNumber,vesselFlag',
    renderer: DoubleLineFlagged<CargoMovementSearchResult>,
    label: 'Vessel,IMO Number',
    labelMobile: 'Vessel',
  },
  owner: {
    field: 'owner',
    renderer: SingleLineTruncated,
    label: 'Owner',
  },
  charterer: {
    field: 'charterer',
    renderer: SingleLineTruncated,
    label: 'Charterer',
  },
  category: {
    field: 'productCategory,productGroup',
    renderer: DoubleLineSimple<CargoMovementSearchResult>,
    label: 'Category,Group',
    labelMobile: 'Commodity',
  },
  quantity: {
    field: 'quantity,productGrade',
    renderer: (data: CargoMovementSearchResult, props: ColumnBodyOptions) =>
      DoubleLineQuantity<CargoMovementSearchResult>(data, props, { quantityTop: true }),
    label: 'Size,Grade',
    labelMobile: 'Size',
  },
  loadPort: {
    field: 'loadPort,loadTerminal',
    renderer: cargoFlowLocation,
    label: 'Load Port,Terminal',
    labelMobile: 'Load Port',
  },
  loadTime: {
    field: 'startUtc',
    renderer: ReadableDateTime<CargoMovementSearchResult>,
    label: 'Arrival,Time',
    labelMobile: 'Load Time',
  },
  dischargePort: {
    field: 'dischargePort,dischargeTerminal',
    renderer: cargoFlowLocation,
    label: 'Discharge Port,Terminal',
    labelMobile: 'Discharge Port',
  },
  dischargeTime: {
    field: 'endUtc',
    renderer: ReadableDateTime<CargoMovementSearchResult>,
    label: 'Discharge,Time',
    labelMobile: 'Discharge Time'
  },
  supplemental: {
    field: 'isStsLine,hasSts',
    renderer: SupplementalBody<CargoMovementSearchResult>,
    label: 'Supplemental'
  },
  probability: {
    field: 'probability',
    renderer: Probability<CargoMovementSearchResult>,
    label: 'Prob.',
  }
}

interface IParams {
  conditionalFilters: ConditionalFilter[];
  handleOrderChange: (field: string) => void;
  orderByColumns: string[];
  ref: any;
  searches: any;
  setIsExportable: Function;
  worksheetId?: string;
}

const PAGE_SIZE: number = 500;

const CargoData = forwardRef<any, IParams>((props, ref) => {
  const { conditionalFilters, searches, setIsExportable, worksheetId, handleOrderChange, orderByColumns } = props;

  // TODO - remove this once paging on scroll is in place
  // eslint-disable-next-line
  const [ pageNumber ] = useState<number>(1);
  const [ queryParams, setQueryParams ] = useState<CargoMovementRequest>({
    searches,
    conditionalFilters,
    orderByColumns,
    pageSize: PAGE_SIZE,
    pageNumber: 1,
  });
  const [ searchResults, setSearchResults ] = useState<CargoMovementSearchResult[]>([]);
  const [ emptyMessage, setEmptyMessage ] = useState<string>('');
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const { data, error, isLoading } = useCargoFlows(queryParams, (r: any) => {
    const [ response ] = r;

    //	if theres something in the data - the EXPORT button can be
    //	activated
    if (response.results.length) {
      setIsExportable(true);
    }
  });

  useImperativeHandle(ref, () => ({
    //  expose a function that allows the export button in the
    //  parent to get the current state of the params
    // @ts-ignore // TODO: Fix Typescript
    getParams: () => ({ queryParams, totalResults: data[0].totalResults }),
  }));

  useEffect(() => {
    if (!worksheetId) {
      return;
    }

    // callbackId parameter also used as indicator
    // the page has been loaded completely
    // and request could be executed
    const callbackId = asEncoded({
      searches,
      pageNumber,
      orderByColumns,
      conditionalFilters,
    });

    //  if search tokens, or order columns, properties
    //  change then re-build the query param object
    setQueryParams((current: CargoMovementRequest) =>
      //  has the search query
      ({
        ...current,
        callbackId,
        searches,
        pageNumber,
        orderByColumns,
        conditionalFilters,
      })
    );
  }, [
    searches,
    orderByColumns,
    pageNumber,
    conditionalFilters,
    worksheetId,
  ]);

  useEffect(() => {
    // reset search results
    setSearchResults([]);
    //	Set Empty message for the table
    setEmptyMessage('Preparing Data…');

    //  if no data then empty the results
    if (!data || !data[0]?.results.length) {
      if (!isLoading) setEmptyMessage('Sorry. No results match that criteria');
      return;
    }

    setSearchResults((current) => {
      //  This is new data - so we want it to be added into the data
      //  bound to the <DataTable>
      const newResults: CargoMovementResponse = (data.at(-1) as CargoMovementResponse);
      return [...current, ...newResults.results]
    });

  }, [data, isLoading]);

  if (error) {
    return <ServiceCallError error={error} />
  }

  const handleColumnOrderChange = (event: DataTableStateEvent) => {
    //  Data isn't exportable while search is running
    setIsExportable(false);
    handleOrderChange(event.sortField);
  }

  const defaults = {
    current: orderByColumns
  }

  return <>{
    isMobile ?
    <CargoDataGridView
      config={DATA_CONFIG}
      data={searchResults}
      emptyMessage={emptyMessage}
      isLoading={isLoading}
    /> :
    <DataTable
      className={clsx(styles.datatable, "grow-to-fill")}
      dataKey="key"
      emptyMessage={ isLoading
        ? <Loader />
        : <div>{emptyMessage}</div>
      }
      onSort={handleColumnOrderChange}
      scrollable
      value={searchResults}
      virtualScrollerOptions={{
        className: "grow-to-fill",
        itemSize: DEFAULT_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
        key: uniqueId(), // force VisrtualScroller to update so it will recalculate it's height
      } as VirtualScrollerProps & { key: Key }}
    >
    <Column
      field={DATA_CONFIG.vessel.field}
      body={DATA_CONFIG.vessel.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.vessel.label}
        by={CargoMovementOrderCriteriaEnum.VesselName}
        isDoubleLined={true}
        {...defaults}
      />
      }

      headerClassName="sorting--server-side header--double-row"
      sortable
      frozen
    />
    <Column
      field={DATA_CONFIG.owner.field}
      body={DATA_CONFIG.owner.renderer}
      header={
        <ServerSortHeader
          label={DATA_CONFIG.owner.label}
          by={CargoMovementOrderCriteriaEnum.Owner}
          {...defaults}
        />
      }
      headerClassName="sorting--server-side"
      sortable
    />
    <Column
      field={DATA_CONFIG.charterer.field}
      body={DATA_CONFIG.charterer.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.charterer.label}
        by={CargoMovementOrderCriteriaEnum.Charterer}
        {...defaults}
      />
      }
      headerClassName="sorting--server-side"
      sortable
    />
    <Column
      field={DATA_CONFIG.category.field}
      body={DATA_CONFIG.category.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.category.label}
        by={CargoMovementOrderCriteriaEnum.ProductCategory}
        isDoubleLined={true}
        {...defaults}
      />
      }
      headerClassName="sorting--server-side"
      sortable
      />
    <Column
      field={DATA_CONFIG.quantity.field}
      body={DATA_CONFIG.quantity.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.quantity.label}
        by={CargoMovementOrderCriteriaEnum.Quantity}
        isDoubleLined={true}
        {...defaults}
      />
      }
      headerClassName={clsx("sorting--server-side", styles.hideable)}
      sortable
      className={styles.hideable}
    />
    <Column
      field={DATA_CONFIG.loadPort.field}
      body={DATA_CONFIG.loadPort.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.loadPort.label}
        by={CargoMovementOrderCriteriaEnum.LoadPort}
        isDoubleLined={true}
        {...defaults}
      />
      }
          headerClassName='sorting--server-side'
      sortable
    />
    <Column
      field={DATA_CONFIG.loadTime.field}
      body={DATA_CONFIG.loadTime.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.loadTime.label}
        by={CargoMovementOrderCriteriaEnum.StartUtc}
        isDoubleLined={true}
        {...defaults}
      />
      }
      headerClassName="sorting--server-side"
      sortable
    />
    <Column
      field={DATA_CONFIG.dischargePort.field}
      body={DATA_CONFIG.dischargePort.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.dischargePort.label}
        by={CargoMovementOrderCriteriaEnum.DischargePort}
        isDoubleLined={true}
        {...defaults}
      />
      }
          headerClassName='sorting--server-side'
      sortable
    />
    <Column
      field={DATA_CONFIG.dischargeTime.field}
      body={DATA_CONFIG.dischargeTime.renderer}
      header={
      <ServerSortHeader
        label={DATA_CONFIG.dischargeTime.label}
        by={CargoMovementOrderCriteriaEnum.EndUtc}
        isDoubleLined={true}
        {...defaults}
      />
      }
      headerClassName="sorting--server-side"
      sortable
    />
    <Column
      field={DATA_CONFIG.supplemental.field}
      body={DATA_CONFIG.supplemental.renderer}
      header={DATA_CONFIG.supplemental.label}
      bodyClassName="no--padding"
    />
    <Column
      field={DATA_CONFIG.probability.field}
      body={DATA_CONFIG.probability.renderer}
      header={DATA_CONFIG.probability.label}
      bodyClassName="contents--fill no--padding"
    />
    </DataTable>
  }</>
});

CargoData.displayName = 'CargoData';

export default memo(CargoData);
