import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import ToastMessage, { ToastMessageRef } from 'components/ToastMessage';

import Subnavigation from '../Subnavigation';
import SupervisorView from '../SupervisorView';

import ProcessedCell from './Templates/ProcessedCell';
import SenderCell from './Templates/SenderCell';

import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';
import { SingleLineEmpty } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineEmpty';
import { formatName } from 'helpers/Utils/string';
import { EscalationResult } from 'modules/Surveillance/Models/ReportsResponse';
import {
  useGetBatch,
  useGetBatches,
} from 'modules/Surveillance/Services/Hooks';

import './EscalationInbox.scss';

const EscalationInbox = (): ReactNode => {
  const { batchparam } = useParams();
  const location = useLocation();
  const { obxuser } = useLoggedInUser();
  const navigate = useNavigate();

  const [securityRights] = useState(() => ({
    escalations: obxuser?.assignedSecurityRights.includes(
      SecurityRights.SurveillanceEscalations
    ),
  }));
  const [selectedItem, setSelectedItem] = useState<EscalationResult | null>(
    null
  );
  const [results, setResults] = useState<EscalationResult[] | undefined>(
    undefined
  );
  const [supervisorViewVisibility, setSupervisorViewVisibility] =
    useState(false);

  const { data: escalatedResponse } = useGetBatch(
    securityRights.escalations ? selectedItem?.id || batchparam : null
  );
  const { data: batches, isLoading } = useGetBatches(
    securityRights.escalations
  );

  const toastRef = useRef<ToastMessageRef>(null);

  const rowClassName = useCallback(
    (data: EscalationResult): string | object | undefined => ({
      newItem: !data.hasBeenRead,
      'p-highlight': selectedItem && data.id === selectedItem.id,
    }),
    [selectedItem]
  );

  const onSupervisorViewHide = useCallback((): void => {
    setSelectedItem(null);
    if (batchparam) {
      // Remove "/[UUID]" from path
      navigate(location.pathname.replace(`/${ batchparam }`, ''), {
        replace: true,
      });
    }
    setSupervisorViewVisibility(false);
  }, [batchparam, location.pathname, navigate]);

  const onRowClick = (e: DataTableRowClickEvent): void => {
    const data = e.data as EscalationResult;
    setSelectedItem(data);
    setResults(results =>
      results?.map(result => {
        if (
          result.id === data.id &&
          result.partitionKey === data.partitionKey
        ) {
          result.hasBeenRead = true;
        }
        return result;
      })
    );
  };

  const supervisorViewHeaderIcons = useMemo(
    () => (
      <div>
        <div
          id='close-dialog-button'
          className='iconoir-xmark icon--small icon--hover-ob-orange dialog-close-button'
          onClick={onSupervisorViewHide}
        />
      </div>
    ),
    [onSupervisorViewHide]
  );

  useEffect(() => {
    const escalationsAccess = obxuser?.assignedSecurityRights.includes(
      SecurityRights.SurveillanceEscalations
    );
    if (!escalationsAccess) {
      /**
       * navigate directly to results page to prevent
       * index redirection from default router without state
       */
      navigate(location.pathname.replace(/escalations.*/, 'results'), {
        state: { noaccess: true },
      });
    }
  }, [obxuser, location, navigate]);

  useEffect(() => setResults(batches), [batches]);

  useEffect(() => {
    if (escalatedResponse) {
      setSupervisorViewVisibility(true);
    }
  }, [escalatedResponse]);

  return (
    <div className='surveillance-escalation-inbox-container grow-to-fill'>
      <Subnavigation />
      <DataTable
        className='surveillance-escalation-inbox__table grow-to-fill'
        dataKey='id'
        loading={isLoading}
        value={results}
        emptyMessage={(): ReactNode => (
          <div>
            <strong>No escalations</strong>
            <br />
            It looks like nothing has been escalated to you currently
          </div>
        )}
        scrollable
        selectionMode='single'
        sortField='received'
        sortOrder={-1}
        onRowClick={onRowClick}
        rowClassName={rowClassName}
      >
        <Column
          header='Sender'
          body={SenderCell}
          field='sender,highImportance'
          sortable
        />
        <Column
          header='Subject'
          field='subject'
          sortable
          body={SingleLineEmpty}
        />
        <Column header='Number Of Items' field='numberOfItems' sortable />
        <Column
          header='Processed'
          field='processed,numberOfItems'
          sortable
          sortField='processed'
          body={ProcessedCell}
        />
        <Column
          header='Received'
          field='received'
          sortable
          body={(data, config): ReactNode =>
            ReadableDate<EscalationResult>(
              data,
              config,
              'dd LLL yyyy, HH:mm:ss ZZZZ'
            )
          }
        />
      </DataTable>
      <Dialog
        className={'p-dialog--margin-less p-dialog--full'}
        visible={supervisorViewVisibility}
        showHeader={true}
        header={`${
          escalatedResponse?.sender && formatName(escalatedResponse.sender)
        } Has Escalated ${ escalatedResponse?.numberOfItems } Item${
          Number(escalatedResponse?.numberOfItems) > 1 ? 's' : ''
        }`}
        icons={supervisorViewHeaderIcons}
        closable={false}
        onHide={onSupervisorViewHide}
      >
        <SupervisorView toastRef={toastRef} escalated={escalatedResponse} />
      </Dialog>
      <ToastMessage ref={toastRef} />
    </div>
  );
};

export default EscalationInbox;
export { EscalationInbox };
