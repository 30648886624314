import { SurveillanceEntityStatus } from 'modules/Surveillance/Models/Enums';
import { ResultsResponse } from 'modules/Surveillance/Models/ReportsResponse';

import styles from './StatusCell.module.scss';

const statusMap: Record<SurveillanceEntityStatus, string> = {
  [SurveillanceEntityStatus.Active]: '',
  [SurveillanceEntityStatus.ActiveWithComments]: 'Returned with comments',
  [SurveillanceEntityStatus.Escalated]: 'Pending',
  [SurveillanceEntityStatus.Reviewed]: 'Marked as reviewed',
};

const StatusCell = (data: Pick<ResultsResponse, 'status'>): JSX.Element => {
  const status = data.status;

  return (
    <i className={styles.surveillanceResultsTableStatusCell}>
      {status ? statusMap[status] : ''}
    </i>
  );
};

export { StatusCell };
export default StatusCell;
