import { ReactNode } from 'react';
import clsx from 'clsx';

import { getValueByPath } from 'helpers/Utils/misc';

import styles from './SenderCell.module.scss';

const SenderCell = <T,>(data: T, config: { field: string }): ReactNode => {
  const { field } = config;
  const [nameField, importanceField] = field.split(',');
  const name = getValueByPath<T>(data, nameField);
  const importance = getValueByPath<T>(data, importanceField);

  return (
    <div
      className={clsx('grow-to-fill', styles.surveillanceEscalationsSenderCell)}
    >
      <div className='grow-to-fill space-between'>
        <div>{name}</div>
        <div className={clsx('importance', { hidden: !importance })}>!</div>
      </div>
    </div>
  );
};

export default SenderCell;
export { SenderCell };
