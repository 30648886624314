import {
  SurveillanceEntityStatus,
  SurveillanceModeEnum,
} from '../Models/Enums';

export const isEntitySelectable = (resultsMode?: SurveillanceModeEnum, recordStatus?: SurveillanceEntityStatus): boolean =>
  (resultsMode &&
    [SurveillanceModeEnum.Results, SurveillanceModeEnum.Reviewed].includes(
      resultsMode
    )) ||
  (resultsMode === SurveillanceModeEnum.Escalated &&
    recordStatus === SurveillanceEntityStatus.Escalated);
