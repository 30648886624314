import useSWR, { MutatorCallback } from 'swr';

import { isSearchRequestEmpty } from '../Components/SearchBar/Models/Parsers';
import { SearchRequestPaged } from '../Models/ReportsRequest';

import { SurveillanceApiService } from './SurveillanceService';

import type {
  EscalationResult,
  SearchFiltersResponse,
  SearchResponse,
} from '../Models/ReportsResponse';

export const useGetResults = (
  sr: SearchRequestPaged,
  worksheetId?: string | null,
  random?: string,
  shouldRequest: boolean = true
): {
  data: SearchResponse | undefined | void;
  error: Error | undefined;
  isValidating: boolean;
  isLoading: boolean;
  searchMutate: MutatorCallback<SearchResponse>;
} => {
  const arg:
    | null
    | [SearchRequestPaged, string | undefined, string | null | undefined] =
    isSearchRequestEmpty(sr) ||
    !worksheetId ||
    worksheetId !== sr.worksheetId ||
    !shouldRequest
      ? null
      : [sr, random, worksheetId];
  const { data, error, isValidating, isLoading, mutate } = useSWR(
    arg,
    () => SurveillanceApiService.getResults(sr, worksheetId ?? ''),
    { revalidateOnFocus: false }
  );

  return { data, error, isValidating, isLoading, searchMutate: mutate };
};

export const useGetBatch = (
  batchId?: string | null
): {
  isValidating: boolean;
  isLoading: boolean;
  data?: EscalationResult | null;
  error?: Error | null;
} => {
  const { data, error, isValidating, isLoading } = useSWR<
    EscalationResult | undefined
  >(batchId, () => SurveillanceApiService.getResultsBatch(batchId!), {
    revalidateOnFocus: false,
  });

  return { data, error, isValidating, isLoading };
};

export const useGetBatches = (
  shouldRequest = true
): {
  data: EscalationResult[] | undefined;
  error: Error | undefined;
  isValidating: boolean;
  isLoading: boolean;
} => {
  const { data, error, isValidating, isLoading } = useSWR(
    shouldRequest ? 'get-escalated-requests' : null,
    () => SurveillanceApiService.getBatches(),
    { revalidateOnFocus: false }
  );

  return { data, error, isValidating, isLoading };
};

export const useGetSurveillanceFilters = (
  shouldRequest = true
): {
  data?: SearchFiltersResponse;
  error?: Error;
} => {
  const { data, error } = useSWR(
    'get-filters',
    () =>
      shouldRequest
        ? Promise.all([
          SurveillanceApiService.getFilterCompanies(),
          SurveillanceApiService.getFilterMedia(),
        ]).then(([company, media]) => ({ company, media }))
        : Promise.resolve(undefined),
    { revalidateOnFocus: false }
  );

  return { data, error };
};
