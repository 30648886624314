import { humanDate } from 'helpers/Utils/formatters';
import { getDOBString } from '../Templates/DOBCell';
import { NameType } from './Enums';

import { apiIndividualSanction, apiDOBSearchModel } from './apiResponse';

export type Detail = {
	key: string;
	value: { value?: string; }[];
};

export class IndividualSanctionResult {
    // Date of birth might be just an empty object, so filter it out and do not display
    private static reduceDoBs (acc: { value: string; }[], dob: apiDOBSearchModel) {
        const dobString = getDOBString(dob);

        return dobString ? [...acc, { value: dobString }] : acc;
    };

    static generateDetails(data: apiIndividualSanction): Detail[] {
        const details: Detail[] = [
			{ key: 'Name', value: data.names.filter(n => n.type === NameType.PrimaryName || n.type === NameType.PrimaryNameVariation) },
			{ key: 'Title', value: data.titles.map(t => ({ value: t })) },
			{ key: 'Sanction Imposed', value: data.sanctionsImposed ? [{ value: data.sanctionsImposed }] : [] },
			{ key: 'AKA', value: data.names.filter(n => n.type === NameType.Alias) },
			{ key: 'Name non-latin script', value: data.nonLatinNames?.map(n => ({ value: n.latinScript })) ?? [] },
			{ key: 'Date(s) of Birth', value: data.individualDetails?.doBs?.reduce(this.reduceDoBs, []) ?? [] },
			{ key: 'Nationality', value: data.individualDetails?.nationalities ?? [] },
			{ key: 'National Identification No.', value: data.individualDetails?.nationalIdentifierDetails?.map(n => ({ value: n.nationalIdentifierNumber })) ?? [] },
			{ key: 'National Identification Additional Info.', value: data.individualDetails?.nationalIdentifierDetails?.reduce((acc, n) => n.nationalIdentifierAdditionalInformation ? [...acc, { value: n.nationalIdentifierAdditionalInformation }] : acc, [] as { value: string; }[]) ?? [] },
			{ key: 'Date(s) of Birth', value: data.individualDetails?.doBs?.reduce(this.reduceDoBs, []) ?? [] },
			{ key: 'Nationality', value: data.individualDetails?.nationalities ?? [] },
			{ key: 'National Identification No.', value: data.individualDetails?.nationalIdentifierDetails?.map(n => ({ value: n.nationalIdentifierNumber })) ?? [] },
			{ key: 'National Identification Additional Info.', value: data.individualDetails?.nationalIdentifierDetails?.reduce((acc, n) => n.nationalIdentifierAdditionalInformation ? [...acc, { value: n.nationalIdentifierAdditionalInformation }] : acc, [] as { value: string; }[]) ?? [] },
			{ key: 'Passport details', value: data.individualDetails?.passportDetails?.map(p => ({ value: p.number, additionalInformation: p.additionalInformation })) ?? [] },
			{ key: 'Positions', value: data.individualDetails?.positions?.map(p => ({ value: p })) ?? [] },
			{ key: 'Address', value: data.addresses ?? [] },
			{ key: 'Email Adresses', value: data.emailAddresses?.map(e => ({ value: e })) ?? [] },
			{ key: 'Phone Numbers', value: data.phoneNumbers?.map(e => ({ value: e })) ?? [] },
			{ key: 'Websites', value: data.websites?.map(w => ({ value: w })) ?? [] },
			{ key: 'Programs', value: data.programs?.map(p => ({ value: p })) ?? [] },
			{ key: 'Gender', value: data.individualDetails?.gender ? [{ value: data.individualDetails.gender }] : [] },
			{ key: 'Date Designated', value: [{ value: data.dateDesignated ? humanDate(data.dateDesignated, { dayName: false }) : 'Unknown' }] },
			{ key: 'Designation Source', value: data.designationSource ? [{ value: data.designationSource }] : [] },
			{ key: 'Created On', value: [{ value: data.createdOn ? humanDate(data.createdOn, { dayName: false }) : 'Unknown' }] },
			{ key: 'Last Updated', value: data.lastUpdatedList?.map(l => ({ value: humanDate(l, { dayName: false }) })) ?? [] },
			{ key: 'Place of Birth', value: data.individualDetails?.birthDetails?.map(({ townOfBirth, countryOfBirth }) => ({ value: townOfBirth, country: countryOfBirth })) ?? [] }
		];

		return details;
    }
}