import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { useIndividualDetailsApi } from 'modules/Sanctions/Services/SanctionsAPI';
import { camelToSpace, capFirstLetter, getTextWithLinks, underscoreToSpace } from 'helpers/Utils/string';

import type { IndividualSanctionResponse } from 'modules/Sanctions/Models/SanctionedItems';

import './SanctionedIndividualOtherInfo.scss';

interface SanctionedIndividualOtherInfoProps {
    selectedItem: IndividualSanctionResponse | null;
};

export default function SanctionedIndividualOtherInfo (props: SanctionedIndividualOtherInfoProps): JSX.Element {
    const { selectedItem } = props;

    const { data } = useIndividualDetailsApi(selectedItem?.id, selectedItem?.listName);

    if (!data) {
        return <div>Please select at item from the table…</div>;

    } else if (!data.additionalInformation) {
        return <div>There is no additional information for this item...</div>;
    }

    return <>
        {data.otherInformation && <div className='sanctioned-individual__other-info'>{getTextWithLinks(data.otherInformation)}</div>}
        {(data.additionalInformation ?? []).map((info) =>
            <DataTable className='sanctioned-individual__additional-info' key={info.key} value={[info]} dataKey='value'>
                <Column
                    body={(data) => getTextWithLinks(data.value)}
                    field="value"
                    header={capFirstLetter(camelToSpace(underscoreToSpace(info.key)))}
                    key={info.key}
                />
            </DataTable>
        )}
    </>;
}