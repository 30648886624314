import { ReactNode } from 'react';

import { SurveillanceActivityResponse } from '../../../Models';

const SimpleTimelineItem = ({
  item,
}: {
  item: SurveillanceActivityResponse;
}): ReactNode => (
  <div className='activity-item'>
    <div className='activity-note-header'>{item.updatedByName}</div>
    <div className='activity-note-content'>{item.note}</div>
    <div className='activity-note-footer'>
      {item.updatedAt.toFormat('dd LLL yyyy, hh:mm')}
    </div>
  </div>
);

export { SimpleTimelineItem };
export default SimpleTimelineItem;
