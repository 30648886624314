export enum DLMessagesStatus {
  null = 'null',
  processed = 'processed',
  dropped = 'dropped',
  deferred = 'deferred',
  bounce = 'bounce',
  delivered = 'delivered',
  open = 'open',
}

export enum DistListModeEnum {
  dl = 1,
  single
}

export const DL_MESSAGES_STATUS_LABEL: { [key in DLMessagesStatus]: string }  = {
  [DLMessagesStatus.null]: 'Unknown',
  [DLMessagesStatus.processed]: 'Sending',
  [DLMessagesStatus.dropped]: 'Dropped',
  [DLMessagesStatus.deferred]: 'Sending',
  [DLMessagesStatus.bounce]: 'Bounced',
  [DLMessagesStatus.delivered]: 'Delivered',
  [DLMessagesStatus.open]: 'Opened',
};

export enum DistListLinkExpireEnum {
  '7 days' = 0,
  '14 days' = 1,
  '30 days' = 2,
  '90 days' = 3,
  'Never Expires' = 4,
}

export enum DistListAttachmentStatus {
  Active = 0,
  Expired
}

export enum DistListDetailsTab {
  message = 'message',
  recipients = 'recipients',
}

export enum SingleSearchGroup {
  Recipient,
  Subject,
  'Attachment Name'
}

export enum CreateSingleMessageMode {
  CreateMessage,
  CopyAddress
}