import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Timeline } from 'primereact/timeline';

import Loader from 'components/Loader';
import { ToastSeverity } from 'components/ToastMessage';

import {
  EntityUpdateResponse,
  useUpdateAuditState,
} from '../../../../Services/AuditService';
import { SurveillanceSignalEventTypes } from '../../../../Services/SignalRSocket';
import { useActivity } from '../../Services/SurveillanceDetailsAPI';

import { ExpandableTimelineItem, SimpleTimelineItem } from './Components';

import { notNil } from 'helpers/Utils/misc';

import { ApplicationInternalEventTypes } from 'models/shared/Enums';
import eventBus from 'server/EventBus';

import type {
  ActivityViewProps,
  SurveillanceActivityResponse,
} from '../../Models';

import './ActivityView.scss';

const ActivityView = ({
  activeWorksheetId,
  record,
  topRibbon,
}: ActivityViewProps): ReactNode => {
  const { id, partitionKey } = record;
  const { activity, isLoading, mutate } = useActivity(
    { id, partitionKey },
    activeWorksheetId
  );
  const [newNote, setNewNote] = useState('');

  const { trigger: triggerUpdate } = useUpdateAuditState(activeWorksheetId);

  const onUpdateClick = (): void => {
    triggerUpdate({
      items: [
        {
          id: id,
          partitionKey: partitionKey,
        },
      ],
      note: newNote,
      worksheetId: activeWorksheetId || '',
    })
      .then((): void => {
        eventBus.dispatch(
          ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
          {
            title: 'Success',
            message: 'Activity Note Updated',
            severity: ToastSeverity.SUCCESS,
          }
        );
        setNewNote('');
      })
      .catch((): void => {
        eventBus.dispatch(
          ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
          {
            title: 'Error',
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR,
          }
        );
      });
  };

  const onEntityUpdated = useCallback(
    (e: CustomEvent<EntityUpdateResponse>): void => {
      const { items, worksheetId } = e.detail;
      if (worksheetId === activeWorksheetId) {
        const indexCurrent: number = items.findIndex(
          d => d.id === id && d.partitionKey === partitionKey
        );
        // If current item found -> update activity
        if (indexCurrent !== -1) {
          mutate();
        }
      }
    },
    [activeWorksheetId, id, partitionKey, mutate]
  );

  useEffect(() => {
    eventBus.on(
      SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES,
      onEntityUpdated
    );

    return (): void => {
      eventBus.remove(
        SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES,
        onEntityUpdated
      );
    };
  }, [onEntityUpdated]);

  if (isLoading) {
    return (
      <div className='activity-loading-container'>
        <div className='activity-loading-content'>
          <Loader className='small' />
        </div>
      </div>
    );
  }

  return (
    <div className='activity-wrap'>
      {topRibbon}
      <div className='activity-container'>
        <div className='activity-content'>
          <InputTextarea
            className='activity-note-input'
            onFocus={(e): void => e.target.select()}
            onChange={(e): void => setNewNote(e.target.value)}
            placeholder='Please add an optional note/comment'
            rows={3}
            value={newNote}
          />
          <Timeline
            content={(item: SurveillanceActivityResponse): ReactNode =>
              // note activity comes without status field
              !notNil(item.status) ? (
                <SimpleTimelineItem item={item} />
              ) : (
                <ExpandableTimelineItem item={item} />
              )
            }
            value={activity}
          />
        </div>
      </div>
      <footer>
        <Button
          size='small'
          severity='success'
          loading={false}
          disabled={newNote.trim() === ''}
          onClick={onUpdateClick}
        >
          Update
        </Button>
      </footer>
    </div>
  );
};

export default ActivityView;
