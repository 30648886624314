import { ReactNode } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { SurveillanceActivityResponse } from '../../../Models';

import { SURV_REASON_TEXT_MAP } from 'modules/Surveillance/Components/MarkAsReviewedPopup/Models/Enums';
import { SURV_ENT_STATUS_TEXT_MAP } from 'modules/Surveillance/Models/Enums';

const ExpandableTimelineItem = ({
  item,
}: {
  item: SurveillanceActivityResponse;
}): ReactNode => (
  <Accordion>
    <AccordionTab
      header={
        <>
          <div>
            Moved to{' '}
            {item.status ? SURV_ENT_STATUS_TEXT_MAP[item.status] : 'Unknown'}
          </div>
          <div className='activity-accordion-date'>
            {item.updatedAt.toFormat('dd LLL yyyy, HH:mm')}
          </div>
        </>
      }
    >
      <div className='activity-accordion-content'>
        {item.previousStatus && (
          <div>
            <dt>Previous</dt>
            <dd>{SURV_ENT_STATUS_TEXT_MAP[item.previousStatus]}</dd>
          </div>
        )}
        {item.status && (
          <div>
            <dt>Current</dt>
            <dd>{SURV_ENT_STATUS_TEXT_MAP[item.status]}</dd>
          </div>
        )}
        {item.updatedByName && (
          <div>
            <dt>Updated by</dt>
            <dd>{item.updatedByName}</dd>
          </div>
        )}
        {item.reason && (
          <div>
            <dt>Reason</dt>
            <dd>{SURV_REASON_TEXT_MAP[item.reason]}</dd>
          </div>
        )}
        <div>
          <dt>Note</dt>
          <dd>
            {item.note ? (
              item.note
            ) : (
              <div className='placeholder'>Have not been added</div>
            )}
          </dd>
        </div>
      </div>
    </AccordionTab>
  </Accordion>
);

export { ExpandableTimelineItem };
export default ExpandableTimelineItem;
