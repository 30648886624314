import axios, { AxiosResponse } from 'axios';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import {
  SearchRequestBatch,
  SearchRequestPaged,
} from '../Models/ReportsRequest';
import { AccessGrantedRequest } from '../Models/Requests';
import { AccessGrantedResponse } from '../Models/Response';

import { parsePropsToDateTime } from 'helpers/Utils/misc';

import type { ExportParams } from '../Models/Export';
import type {
  EscalationResult,
  ResultsResponse,
  SearchResponse,
} from '../Models/ReportsResponse';

export class SurveillanceApiService {
  static getResults = (
    data: SearchRequestPaged,
    worksheetId: string
  ): Promise<SearchResponse> =>
    axios
      .request({
        url: 'audit/surveillance/search',
        data: data,
        method: 'POST',
        headers: {
          worksheetId,
        },
      })
      .then((results: AxiosResponse<SearchResponse>) => ({
        ...results.data,
        results: this.updateResultsData(
          results.data.results,
          data.pageNumber,
          data.pageSize
        ),
      }))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);

        throw e;
      });

  static getResultsBatch = (batchId: string): Promise<EscalationResult> =>
    axios
      .request<SearchRequestBatch, AxiosResponse<EscalationResult>>({
        url: `audit/surveillance/batches/${ batchId }`,
        method: 'GET',
      })
      .then(response => this.updateEscalationsResult(response.data))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);

        throw e;
      });

  static updateResultsData = (
    data: ResultsResponse[],
    pageNumber: number,
    pageSize: number
  ): ResultsResponse[] =>
    data.map((el, index) =>
      this.updateResultsResponse(el, index, pageNumber, pageSize)
    );

  static getBatches = (): Promise<EscalationResult[]> =>
    axios
      .request<null, AxiosResponse<EscalationResult[]>>({
        url: 'audit/surveillance/batches',
        method: 'GET',
      })
      .then(response => response.data.map(this.updateEscalationsResult))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);

        throw e;
      });

  static updateResultsResponse = (
    r: ResultsResponse,
    index: number,
    pageNumber: number,
    pageSize: number
  ): ResultsResponse => ({
    ...parsePropsToDateTime(r, ['startTime', 'stopTime']),
    elementCounter: (pageNumber - 1) * pageSize + index + 1, // Added for testing pagination
  });

  static updateEscalationsResult = (
    data: EscalationResult
  ): EscalationResult => ({
    ...parsePropsToDateTime(data, ['received']),
    items: data.items.map(item => ({
      ...parsePropsToDateTime(item, ['startTime', 'stopTime']),
    })),
  });

  static exportResults = (
    params: ExportParams,
    worksheetId: string
  ): Promise<ExportParams | null> =>
    axios<ExportParams>({
      url: 'audit/surveillance/export',
      data: params,
      method: 'POST',
      headers: {
        worksheetId,
      },
    })
      .then(() => params)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });

  static getFilterCompanies = (): Promise<string[] | null> =>
    axios<string[]>({
      url: 'audit/surveillance/companies',
      method: 'GET',
    })
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        return null;
      });

  static getFilterMedia = (): Promise<string[] | null> =>
    axios<string[]>({
      url: 'audit/surveillance/media',
      method: 'GET',
    })
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        return null;
      });

  static sendAccessGranted = (
    params: AccessGrantedRequest,
    worksheetId: string
  ): Promise<AccessGrantedResponse | null> =>
    axios<AccessGrantedResponse>({
      url: `Worksheets/Surveillance/${ worksheetId }/GrantAccess`,
      data: params,
      method: 'POST',
      headers: {
        worksheetId,
      },
    })
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });
}
