import { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import clsx from 'clsx';

import BorealisBar from 'components/BorealisBar';
import { DataModuleApi } from 'modules/DataModule/Services/DataModuleAPI';

import type { SubscriptionRequest } from 'modules/DataModule/Models/SubscriptionRequest';
import type { SubscriptionResponseFlat } from 'modules/DataModule/Models/SubscriptionResponse';
import type { InvalidItem, ValidItem } from 'modules/DataModule/Models/ValidatedProducts';
import type F from 'types/generic-type';

type SubscriptionProductsProps = {
  errors: F<string> | null;
  request: SubscriptionRequest;
  selectedSubscription: SubscriptionResponseFlat | null;
  mutate: (mutation: Partial<SubscriptionRequest>) => void;
};

const SubscriptionProducts = (props: SubscriptionProductsProps): JSX.Element => {
  const { errors, request, selectedSubscription, mutate } = props;

  const [validProducts, setValidProducts] = useState<ValidItem[]>([]);
  const [invalidProducts, setInvalidProducts] = useState<InvalidItem[]>([]);
  const [isValidatingProducts, setIsValidatingProducts] = useState<boolean>(false);

  useEffect(() => {
    // validate products when the Details Panel is open so the list with names and IDs can be displayed
    if (validProducts.length || invalidProducts.length || selectedSubscription?.id !== request.id) {
      return;
    }

    validateProducts(request.resources.join(';'));
    // eslint-disable-next-line
  }, [selectedSubscription?.id]);

  useEffect(() => {
    if (selectedSubscription && selectedSubscription.id !== request.id) {
      return;
    }

    mutate({ resources: validProducts.map(item => item.id) });
  }, [validProducts]);

  const validateProducts = async (value: string): Promise<void> => {
    if (value) {
      setIsValidatingProducts(true);
      const result = await DataModuleApi.validateProducts(request.dataSet, value);

      setValidProducts(valid => [...valid, ...result.validItems.filter(i => !valid.find(j => i.id === j.id))]);
      setInvalidProducts(invalid => [...invalid, ...result.invalidItems.filter(i => !invalid.find(j => i === j))]);
      setIsValidatingProducts(false);
    }
  };

  const revalidateProduct = (product: string, value: string): void => {
    setInvalidProducts(invalid => invalid.filter(pr => pr !== product));
    validateProducts(value);
  };

  const handleProductsChange = (e: SyntheticEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();
    validateProducts((e.target as HTMLTextAreaElement).value);
    (e.target as HTMLTextAreaElement).value = '';
  };

  return <div className='subscription-details__products'>
    <header className='subscription-details__products--header grow-to-fill'>
      Products {!!validProducts.length && `(${validProducts.length})`}
    </header>
    <div className='position--relative'>
      {isValidatingProducts && <BorealisBar />}
    </div>
    <div className='subscription-details__form direction--column'>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.resources })}>
      <InputTextarea
        rows={1}
        autoResize
        placeholder='Add products'
        onBlur={handleProductsChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleProductsChange(e);
          }
        }}
      />
      </div>
      {errors?.resources && <small className='message-invalid'>Required field</small>}

      {!!invalidProducts.length && <div className='subscription-details__invalid-products direction--column'>
        <div className='subscription-details__invalid-products--label'>
          <i className='iconoir-priority-high icon--tiny' />
          <span>Sorry, but we don't have this products</span>
        </div>
        <div className='subscription-details__invalid-products--list'>
          {invalidProducts.map(product => <InputText
            key={product}
            defaultValue={product}
            onFocus={(e) => e.target.select()}
            onBlur={(e) => revalidateProduct(product, e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                revalidateProduct(product, (e.target as HTMLInputElement).value);
              }
            }}
          />
          )}
        </div>
      </div>}
      <div className='subscription-details__valid-products--list'>
        {validProducts.map(product =>
          <div
            className='subscription-details__valid-products--list-item'
            key={product.id}
          >
            <div>
              <dt>{product.name}</dt>
              <dd>{product.id}</dd>
            </div>
            <Button
              text
              type='button'
              size='small'
              className='p-button-icon-only iconoir-trash icon--tiny icon-secondary'
              onClick={() => setValidProducts(cur => cur.filter(res => res.id !== product.id))}
            />
          </div>
        )}
      </div>
    </div>
  </div>;
};

export default SubscriptionProducts;