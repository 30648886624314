export default function ArrayValueCell (props: any[], key: string): JSX.Element {
    if (!props[0] || !key) {
        return <></>;
    }

    const hiddenItemCount = props.length - 1;

    return <>
        <div className='visible-items'>{props[0]?.[key]}</div>
        {hiddenItemCount > 0 ? <span className='hidden-items'>(+{hiddenItemCount} others)</span> : <></>}
    </>;
}
