import axios, { AxiosError } from 'axios';
import { DateTime, Duration } from 'luxon';
import useSWR, { Key, KeyedMutator, State, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';

import { DATA_DEFAULT_VALUES } from '../Models/Consts';
import { SubscriptionDataset } from '../Models/Enums';

import { parsePropsToDateTime } from 'helpers/Utils/misc';

import type {
  ResendAccessTokenParams,
  SubscriptionRequest,
} from '../Models/SubscriptionRequest';
import type {
  SubscriptionDetails,
  SubscriptionResponse,
} from '../Models/SubscriptionResponse';
import type { ValidatedProducts } from '../Models/ValidatedProducts';

type GetSubscriptionReturnType = {
  data: SubscriptionResponse[] | undefined;
  error: AxiosError | undefined;
  isLoading: boolean;
  isValidating: boolean;
  mutate: KeyedMutator<SubscriptionResponse[]>;
};

export const useGetSubscriptions = (): GetSubscriptionReturnType => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<SubscriptionResponse[], AxiosError | undefined, Key>(
    'data-module-subscriptions',
    DataModuleApi.getSubscriptions,
    { revalidateOnFocus: false });

  return { data, error, isLoading, isValidating, mutate };
};

type GetSubscriptionDetailsPayload = {
  id?: string;
  partitionKey?: string;
};

type GetSubscriptionDetailsReturnType = {
  data: SubscriptionDetails | undefined;
  error: AxiosError | undefined;
  isLoading: boolean;
  isValidating: boolean;
};

export const useGetSubscriptionDetails = (arg: GetSubscriptionDetailsPayload): GetSubscriptionDetailsReturnType => {
  const { data, error, isLoading, isValidating } = useSWR<SubscriptionDetails, AxiosError | undefined, Key>(
    arg.id && arg.partitionKey ? `data-module-subscription-${arg.id}-${arg.partitionKey}` : null,
    () => DataModuleApi.getSubscriptionDetails(arg),
    { revalidateOnFocus: false });

  return { data, error, isLoading, isValidating };
};

type SaveSubscriptionReturnType = {
  trigger: (arg: SubscriptionRequest) => Promise<SubscriptionDetails | undefined>;
  error: AxiosError | undefined;
  isMutating: boolean;
};

export const useSaveSubscription = (): SaveSubscriptionReturnType => {
  const { cache } = useSWRConfig();
  const { trigger, error, isMutating } = useSWRMutation<
    SubscriptionDetails,
    AxiosError | undefined,
    Key,
    SubscriptionRequest
  >('data-module-save-subscription', DataModuleApi.saveSubscription, {
    revalidate: false,
    onSuccess: (response: SubscriptionDetails) => {
      //	Update the current cache
      cache.set(
        `data-module-subscription-${ response.id }-${ response.partitionKey }`,
        response as State<SubscriptionDetails>
      );
    },
  });

  return { trigger, error, isMutating };
};

type GetRegionsReturnType = {
  data: string[] | undefined;
  error: AxiosError | undefined;
  isLoading: boolean;
  isValidating: boolean;
};

export const useGetRegions = (): GetRegionsReturnType => {
  const { data, error, isLoading, isValidating } = useSWR<string[], AxiosError | undefined, Key>(
    'data-module-regions',
    DataModuleApi.getRegions,
    { revalidateOnFocus: false });

  return { data, error, isLoading, isValidating };
};

export class DataModuleApi {
  static getSubscriptions = (): Promise<SubscriptionResponse[]> =>
    axios.get<SubscriptionResponse[]>('dataManagement/subscriptions')
      .then(results => results.data.map(data => ({
        ...data,
        items: data.items.map(item => parsePropsToDateTime(item, ['startDate', 'expiryDate', 'createdUtc', 'lastModified']))
      })))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 401, 403, 500, 503]);

        throw e;
      });

  static getSubscriptionDetails = (arg: GetSubscriptionDetailsPayload): Promise<SubscriptionDetails> =>
    axios.get<SubscriptionDetails>(`dataManagement/subscriptions/${arg.id}/${arg.partitionKey}`)
      .then(results => parsePropsToDateTime(results.data, ['startDate', 'expiryDate', 'createdUtc', 'lastModified']))
      .catch(e => { throw e; });

  static saveSubscription = (_url: string, params: { arg: SubscriptionRequest; }): Promise<SubscriptionDetails> => {
    const { id, partitionKey } = params.arg;
    const url = id ? `dataManagement/subscriptions/${id}/${partitionKey}` : 'dataManagement/subscriptions';
    const method = id ? 'PUT' : 'POST';

    return axios.request({
      url,
      method,
      data: params.arg
    })
      .then(results => parsePropsToDateTime(results.data, ['startDate', 'expiryDate', 'createdUtc', 'lastModified']))
      .catch(e => { throw e; });
  };

  static resendAccessToken = (
    _url: string,
    params: { arg: ResendAccessTokenParams }
  ): Promise<void> => {
    const { id, partitionKey } = params.arg;
    const url = `dataManagement/subscriptions/${ id }/${ partitionKey }/reset`;
    const method = 'PATCH';

    return axios
      .request({
        url,
        method,
        data: params.arg,
      })
      .then(response => response.data);
  };

  static validateProducts = (dataset: SubscriptionDataset, products: string): Promise<ValidatedProducts> =>
    axios.request<ValidatedProducts>({
      method: 'POST',
      url: `/dataManagement/subscriptions/${dataset}/products/validate`,
      data: { products }
    })
      .then(results => results.data)
      .catch(e => { throw e; });

  static getRegions = (): Promise<string[]> =>
    axios.get<string[]>('/dataManagement/regions')
      .then(results => results.data)
      .catch(e => { throw e; });

  static createEmptySubscription = (dataSet: SubscriptionDataset): SubscriptionRequest => {
    const COMMON_PROPS = {
      id: null,
      partitionKey: null,
      dataSet,
      subscriptionName: '',
      clientEmail: '',
      clientName: '',
      startDate: DateTime.utc(),
      isTrial: true,
      marketClose: '16:30',
      numberOfDays: DATA_DEFAULT_VALUES.trial.numberOfDays,
      timeWindow: [],
      callsPerMinute: null,
      resources: [],
    };

    // TODO: return proper schema based on `dataset`
    switch (dataSet) {
      case SubscriptionDataset.RateGridsPackage:
      default:
        return {
          ...COMMON_PROPS,
          subscriptions: [],
          regions: [],
          expiryDate: DateTime.utc().plus(Duration.fromObject({ days: DATA_DEFAULT_VALUES.trial.numberOfDays })),
          groupable: true,
          maxHistoryDays: DATA_DEFAULT_VALUES.trial.maxHistoryDays,
          maxProducts: DATA_DEFAULT_VALUES.trial.maxProducts,
        };
      case SubscriptionDataset.DerivativesExchanges:
        return {
          ...COMMON_PROPS,
          subscriptions: [],
          regions: [],
          expiryDate: DateTime.utc().plus(Duration.fromObject({ days: DATA_DEFAULT_VALUES.trial.numberOfDays })),
          maxHistoryDays: DATA_DEFAULT_VALUES.trial.maxHistoryDays,
          maxProducts: DATA_DEFAULT_VALUES.trial.maxProducts,
        };
    }
  };
}
