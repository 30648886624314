import * as React from "react";
import { useMediaQuery } from "react-responsive";

import { clsx } from "clsx";
import axios from "axios";
import fileDownload from "js-file-download";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import ErrorToastService from 'components/Errors/ErrorToast/Services';
import DateTimeRange, { type ExternalHandles } from 'components/DateTimeRange';
import { laycanStyleRange } from 'helpers/Utils/formatters';

import { ResultsModeEnum } from "../../Models/Enums";

import type { SearchParams } from 'modules/Sanctions/Models';

interface ISanctionsProps {
  displayLoader: Boolean;
  currentSearchMode: ResultsModeEnum;
  currentSearchParams: SearchParams;
  mutateSearchParams: (arg: Partial<SearchParams>) => void;
}


function SanctionsSearch(props: ISanctionsProps) {

  const { displayLoader, currentSearchMode, currentSearchParams, mutateSearchParams } = props;
  const { dateRange, searchTerm } = currentSearchParams;

  const [isParsingDate, setIsParsingDate] = React.useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const menu = React.useRef<Menu>(null);
  const dateTimeRef = React.useRef<ExternalHandles>(null);

  const downloadFile = (path: string, file: string) => {

    //  path should be root relative so if doesn"t start and end with a /
    //  then lets add them
    path = (path.match(/(^\/.*\/$)+/g)) ? path : `/${path}/`;

    const url: string = `${window.location.origin}${path}${file}`
    axios({
      url,
      method: "GET",
      responseType: "blob"
    })
    .then(r => fileDownload(r.data, file))
      .catch((e) => {
        ErrorToastService.handleError(e, [500, 503]);

        // handle error state
        throw e;
      });
  }

  const handleClear = (): void => {
    mutateSearchParams({ searchTerm: '*', dateRange: null });
    dateTimeRef.current?.reset();
  }

  const downloadItems = [
    { label: "Sanctions Procedure",
      command: () => {
        downloadFile("/", "Oil Brokerage - Ship Brokering Sanctions Procedure.pdf");
      }
    },
    { label: "Russian Trade Form",
      command: () => {
        downloadFile("/", "Russian – General Trade Licence Record Form.pdf");
      }
    },
    { label: "UML Price Cap Compliance",
      command: () => {
        downloadFile("/", "UML - Oil Price Cap Sanctions Compliance 03.03.2023.pdf");
      }
    },

  ]

  const placeholderSearchModeString = React.useMemo(() => {
    switch (currentSearchMode) {
      case ResultsModeEnum.IndividualSanctions:
        return 'an Individual';
      case ResultsModeEnum.LegalEntitySanctions:
        return 'an Entity';
      case ResultsModeEnum.VesselSanctions:
      default:
        return 'a Vessel';
    }
  }, [currentSearchMode]);

  return <>
    <div className="sanctions__search-container">
      <div className="p-input-icon-left grow-to-fill form-input__container" >
        <i className={clsx("pi", {
          "pi-spinner pi-spin": displayLoader,
          "pi-search": !displayLoader
        })} />
        <InputText
          placeholder={`Search for ${placeholderSearchModeString}`}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            mutateSearchParams({ searchTerm: ev.target.value });
          }}
          onFocus={(ev: React.FocusEvent<HTMLInputElement>) => {
            ev.target.select();
          }}
          className="grow-to-fill"
          value={searchTerm}
        />
      </div>
      <DateTimeRange
        ref={dateTimeRef}
        className='sanctions__search-datetime-input'
        defaultValue={dateRange ? laycanStyleRange(dateRange.fromDate, dateRange.toDate) : ''}
        onDateParsed={(date) => {
          mutateSearchParams({ dateRange: { fromDate: date.fromString, toDate: date.toString } });
          setIsParsingDate(false);
        }}
        onParsingStart={() => setIsParsingDate(true)}
        onDateParseError={() => setIsParsingDate(false)}
        onEmptyValue={() => mutateSearchParams({ dateRange: null })}
        placeholder='Last Updated'
        required={false}
      />
      <Button
        text
        size='small'
        onClick={handleClear}
        disabled={(!searchTerm && !dateRange) || isParsingDate}
      >
        Clear
      </Button>
    </div>
    <Menu
      model={downloadItems}
      popup
      ref={menu}
    />
    <Button
      icon={clsx(
        "icon--small icon--ob-orange",
        {
          "iconoir-nav-arrow-down icon--right": !isTabletOrMobile,
          "iconoir-download": isTabletOrMobile
        })}
      size="small"
      iconPos="right"
      outlined
      onClick={(e) => (menu.current as Menu).toggle(e)}
    >
      {!isTabletOrMobile &&
        <>
        <i className="iconoir-download icon--small icon--ob-orange" />
        Downloads
        </>
      }
    </Button>

  </>;
}

export default SanctionsSearch;
