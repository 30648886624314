import { useEffect, useState } from 'react';

import FacetBarChart from 'components/Charts/FacetBarChart';

import { ResultsModeEnum } from '../../Models/Enums';
import { useSanctionsApi } from '../../Services/SanctionsAPI';

import type { SearchParams } from 'modules/Sanctions/Models';

import "./SanctionedVesselCharting.scss";

interface ISanctionsChartingParams {
  searchParams: SearchParams;
}

function SanctionedVesselCharting(props: ISanctionsChartingParams) {

  const { searchParams } = props;

  const { data } = useSanctionsApi(searchParams, ResultsModeEnum.VesselSanctions);
  // TODO: handle loading & error state
  const [ flagFacets, setFlagFacets ] = useState<any[]>();
  const [ ownerFacets, setOwnerFacets ] = useState<any[]>();
  const [ groupFacets, setGroupFacets ] = useState<any[]>();

  useEffect(() => {
    if (!data) return;

    let { searchFacets } = data;

    setFlagFacets(searchFacets.filter((item:any ) => item.field === "FlagName").map(
      (item: any) => {
        //  TODO - should shift this transformation server-side
        if (item.value === "United States Of America") {
          item = {...item, value: "USA"};
        }
        return item;
      }
    ));

    setOwnerFacets(searchFacets.filter((item:any ) => item.field === "RegisteredOwner"));
    setGroupFacets(searchFacets.filter((item:any ) => item.field === "GroupBeneficialOwner"));
  }, [data])

  return <>
    {data &&
    <div className="sanctioned-entity-charting__container overflow--y">
      <figure>
        <figcaption>
          <strong>Flags</strong>
          <span>Number of matching vessels ranked by country of registration</span>
        </figcaption>
        <FacetBarChart title='Flags' facets={flagFacets as any}/>
      </figure>

      <figure>
        <figcaption>
          <strong>Registered Owner</strong>
          <span>Number of …</span>
        </figcaption>
        <FacetBarChart title='Registered Owner' facets={ownerFacets as any}/>
      </figure>

      <figure>
        <figcaption>
          <strong>Group Beneficial Owner</strong>
          <span>Number of …</span>
        </figcaption>
        <FacetBarChart title='Registered Owner' facets={groupFacets as any}/>
      </figure>
    </div>
    }
  </>
}

export default SanctionedVesselCharting;
