import { forwardRef, ReactNode, FocusEvent, SyntheticEvent, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { AutoComplete, type AutoCompleteCompleteEvent, type AutoCompleteProps } from 'primereact/autocomplete';
import { useDebounce } from 'primereact/hooks';
import { clsx } from 'clsx';

import { SEARCH_DEBOUNCE_DELAY, type SearchSuggestionsResponse } from './Models/SearchEntities';
import { uniqueId } from 'helpers/Utils/string';

import { useSearchSuggestions } from './Services/SearchEntitiesAPI';

import type { EntitySearchGroupEnum, EntitySearchFieldsEnum } from './Models/Enums';

import styles from './SingleEntitySearch.module.scss';

export const enum AddOnPosition {
	Left = "left",
	Right = "right"
}

export type ExternalHandles = {
	reset: () => void;
	suggestions: SearchSuggestionsResponse[];
}

type CustomItem = Partial<SearchSuggestionsResponse> & {
	searchEntityId: SearchSuggestionsResponse['searchEntityId'];
	value: SearchSuggestionsResponse['value'];
	template: (item: SearchSuggestionsResponse) => ReactNode;
} // update if needed

interface ISingleEntitySearchParams {
	addOn?: JSX.Element | null;
	addOnPosition?: AddOnPosition;
	allowCustomSelection?: boolean
	callback: Function;
	chunkSize?: number;
	queryStringParams?: Record<string, any>;
	className?: string;
	color?: string;
	customItems?: CustomItem[];
	fields: EntitySearchFieldsEnum | EntitySearchFieldsEnum[];
	group?: boolean;
	icon?: string;
	initialTerm?: string,
	isDisabled?: boolean;
	itemTemplate?: any // TODO - get a type for this…
	label: string,
	module: EntitySearchGroupEnum;
	onInputClear?: (event: SyntheticEvent<Element, Event>) => void;
	showError?: boolean;
	errorVisibleAfterTouch?: boolean;
	placeholder?: string;
	showCustomItem?: boolean;
	completeMethod?: (change?: string) => void;
	onBlurMethod?: (event: FocusEvent<HTMLInputElement>) => void;
	panelClassName?: string;
	onFocusMethod?: (event: FocusEvent<HTMLInputElement>) => void;
	onClickMethod?: (event: SyntheticEvent<HTMLInputElement, PointerEvent>) => void;
	autocompletePt?: AutoCompleteProps['pt'];
	customErrorMessage?: string;
}


export const SingleEntitySearch = forwardRef<ExternalHandles, ISingleEntitySearchParams>((props: ISingleEntitySearchParams, ref): JSX.Element => {

	const {
		addOn,
		addOnPosition = AddOnPosition.Left,
		allowCustomSelection,
		autocompletePt,
		callback,
		chunkSize,
		queryStringParams,
		className,
		color,
		completeMethod,
		customItems,
		errorVisibleAfterTouch = true,
		fields,
		group,
		icon,
		initialTerm,
		isDisabled,
		itemTemplate,
		label,
		module,
		onBlurMethod,
		onClickMethod,
		onFocusMethod,
		onInputClear,
		panelClassName,
		placeholder,
		showCustomItem,
		showError,
		customErrorMessage
	} = props;

	const [term, debouncedTerm, setTerm] = useDebounce(initialTerm ?? "", SEARCH_DEBOUNCE_DELAY);
	const [ filtered, setFiltered] = useState<any[]>([]);
	const [ keyPressed, setKeyPressed ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
	const [ touched, setTouched ] = useState(false);
	const [ shouldShowCustomItems, setShouldShowCustomItems] = useState<boolean>(false);
	const autocompleteRef = useRef<AutoComplete>(null);

	const { data, error, isLoading } = useSearchSuggestions<string, SearchSuggestionsResponse[]>({ module, fields, term: debouncedTerm, chunkSize, preventRun: !keyPressed, queryStringParams });

	useImperativeHandle(ref, () => ({
		reset() {
			console.log("Reset...");
			setTerm("");
		},
		suggestions: filtered
	}))

	let others: any = {
		placeholder,
		onBlur: (e: FocusEvent<HTMLInputElement>) => {
			setTouched(true);
			onBlurMethod && onBlurMethod(e);
		}
	}

	if (allowCustomSelection) {
		//	need to add a onKeyUp function prop to trigger the callback
		const process = (e: KeyboardEvent | FocusEvent): void => {
			if (("key" in e && e.key === "Enter") || e.type === "blur") {
				const { target  } = e;
				//  Trigger the callback method
				callback((target as HTMLInputElement)?.value);
			}
		}

		others = {
			...others,
			onKeyUp: process,
			onBlur: (e: KeyboardEvent | FocusEvent) => {
				process(e);
				setTouched(true);
			}
		}
	} else {
		others = {
			...others,
			showEmptyMessage: true
		}
	}

	// generate id once. Otherwise id is changing rapidly and updates DOM many times, ex. on key stroke
	const id: string = useMemo(() => `${uniqueId()}-input`, []);

	useEffect(() => {
			if (!data) return;
			console.log(data)
		setFiltered(() => data ?? []);

		// TODO - This is ugly. But gets around issue #2972 and search results
		// sometimes being hidden despiste their being data to display.
		if (document.activeElement as unknown === autocompleteRef.current?.getInput()) {
			autocompleteRef.current?.show();
		}
	}, [data])

	useEffect(() => {
		setTerm(initialTerm ?? "")
		// eslint-disable-next-line
	}, [initialTerm]);

	useEffect(() => {
		setErrorMessage(customErrorMessage || ((!term || term === '') && (!errorVisibleAfterTouch || touched) ? 'Required field' : null));
	}, [customErrorMessage, errorVisibleAfterTouch, term, touched]);

  if (error) {
    //  TODO - created a filtered set that shows as empty
  }

	useEffect(() => {
		if (showCustomItem && customItems && (!term || term === initialTerm)) {
			// set cutom items as suggestions so it can be shown when Autocomplete list is open
			setFiltered(() => customItems);
			setShouldShowCustomItems(true);

			// If term is empty and input is focused, show panel. handleOnClear is executed before so doesn't have updated value
			if (term === '' && document.activeElement as unknown === autocompleteRef.current?.getInput()) {
				autocompleteRef.current?.show();
			}
		} else {
			setFiltered(() => data ?? []);
			setShouldShowCustomItems(false);
		}
	}, [data, customItems, initialTerm, showCustomItem, term]);

		const handleOnClear = (e: SyntheticEvent<Element, Event>) => {
		setTerm('');
		typeof onInputClear === 'function' && onInputClear(e);

		if (shouldShowCustomItems) {
			autocompleteRef.current?.show();
		}
	}

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>):void => {
    if (shouldShowCustomItems) {
      autocompleteRef.current?.show();
    }
    onFocusMethod && onFocusMethod(event);
  };

	const autocomplete = <AutoComplete
		ref={autocompleteRef}
		inputId={id}
		value={term}
		suggestions={filtered} // force Autocomplete to show suggestions
		loadingIcon={<></>}
		emptyMessage={isLoading ? "Searching…" : "Sorry. No matches for that search…"}
		completeMethod={(e: AutoCompleteCompleteEvent) => {
			completeMethod && completeMethod(e.query);
		}}
		onFocus={handleOnFocus}
		onClear={handleOnClear}
		onChange={(e) => {
			setKeyPressed(true);
			setTerm(e.target.value);
		}}
		onShow={() => console.log('show')}
		disabled={isDisabled}
		scrollHeight="40dvh"
		placeholder={placeholder}
		itemTemplate={shouldShowCustomItems ?
			(item) => item.template(item) :	// use Items's own template
			itemTemplate
		}
		onSelect={(e) => {
			setTerm(e.value.value);
			//  Trigger the callback method
			callback(e.value);
			autocompleteRef.current?.hide();
		}}
		onClick={onClickMethod}
		className={clsx(isDisabled && "iconoir-lock icon--small")}
		inputClassName={clsx(
			icon && styles.paddedInput,
			{ 'p-invalid': showError && errorMessage !== null }
		)}
		panelClassName={clsx(
			{
				'autocomplete-entity': filtered.length || !allowCustomSelection,
				'empty': !filtered.length,
			},
			panelClassName
		)}
		{...others}
		pt={autocompletePt}
	/>;

  return <>
    <div className={clsx(
			"form-input__container",
      icon && `iconoir-${icon} icon--small`,
			showError && errorMessage && 'p-invalid',
      className,
		)}>
      { group &&
        <span className={clsx(
          `p-inputgroup-addon`,
          color && `addon-color--${color}`
        )}>
          <i className={`iconoir-${icon} icon--small`}></i>
        </span>
      }
			{ label &&
				<label htmlFor={id}>{label}</label>
			}
			{ addOn
					? <div className={clsx({
							'p-inputgroup': addOn
						})}>
							{ addOnPosition === AddOnPosition.Left &&
								(<span className='p-inputgroup-addon'>
									{ addOn }
								</span>)}
							{ autocomplete }
						{ addOnPosition === AddOnPosition.Right &&
						(<span className='p-inputgroup-addon'>
									{ addOn }
								</span>)}
						</div>
					: <>{ autocomplete }</>
			}
			{ showError && errorMessage && <small className="message-invalid">{ errorMessage || 'Invalid input' }</small> }
    </div>
  </>;
});

SingleEntitySearch.displayName = 'SingleEntitySearch';

export default SingleEntitySearch;
