import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import {
  EntitySearchFieldsEnum,
  EntitySearchGroupEnum,
} from 'components/EntitySearch/Models/Enums';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';

import { SurveillanceEntityStatus } from '../../Models/Enums';
import { ResultsResponse } from '../../Models/ReportsResponse';
import { StateRequest, useUpdateAuditState } from '../../Services/AuditService';

import { escalateValidator } from './Models/Validators';

import { formatName } from 'helpers/Utils/string';

import type { SuggestionResponse } from '../../../CargoTracker/Components/GroupedSearch';
import type { SelectedContentMessage } from '../RightPanel/Models';

import styles from './EscalatePopup.module.scss';

export interface EscalatePopupReferenceProps {
  submit: () => Promise<StateRequest | undefined>;
}

export interface EscalatePopupProps {
  activeWorksheetId: string;
  selectedItems: ResultsResponse[];
  siblingMessages: SelectedContentMessage[];
}

const EscalatePopup = forwardRef<
  EscalatePopupReferenceProps,
  EscalatePopupProps
>(({ activeWorksheetId, selectedItems, siblingMessages }, ref): JSX.Element => {
  const [assignedUserId, setAssignedUserId] = useState<string | null>(null);
  const [subject, setSubject] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [isHighImportance, setIsHighImportance] = useState(false);
  const [isValidationVisible, setIsValidationVisible] =
    useState<boolean>(false);
  const [validationMessages, setValidationMessages] =
    useState<Record<string, string>>();

  const { trigger: triggerUpdate } = useUpdateAuditState(activeWorksheetId);

  useImperativeHandle(ref, () => ({
    submit: (): Promise<StateRequest | undefined> => {
      if (!validationMessages || !Object.keys(validationMessages).length) {
        return triggerUpdate({
          items: selectedItems.map(el => ({
            id: el.id,
            partitionKey: el.partitionKey,
            siblings: siblingMessages
              .filter(sbl => sbl.parent?.id === el.id)
              .map(sbl => ({
                id: sbl.id,
                partitionKey: sbl.partitionKey,
              })),
          })),
          assignedUserId: assignedUserId,
          status: SurveillanceEntityStatus.Escalated,
          note: notes || '',
          worksheetId: activeWorksheetId || '',
          highImportance: isHighImportance,
          subject,
        });
      } else {
        setIsValidationVisible(true);
        return Promise.reject('validator');
      }
    },
  }));

  useEffect(() => {
    if (!activeWorksheetId) {
      return;
    }

    const result = escalateValidator.validate(
      { assignedUserId, highImportance: isHighImportance, subject, notes },
      { convert: false }
    );

    setValidationMessages(
      result.error?.details.reduce(
        (acc, value) => ({
          ...acc,
          [value.context?.key || '']: value.message,
        }),
        {} as Record<string, string>
      )
    );
  }, [activeWorksheetId, assignedUserId, isHighImportance, subject, notes]);

  return (
    <form>
      <div className={styles.surveillanceEscalatePopup}>
        <div className={styles.surveillanceEscalatePopupPerson}>
          <SingleEntitySearch
            label='Person*'
            placeholder='Select Person'
            module={EntitySearchGroupEnum.Users}
            fields={EntitySearchFieldsEnum.User}
            queryStringParams={{ additionalFilter: 'SecurityRights=1010' }}
            itemTemplate={(i: SuggestionResponse): string =>
              formatName(i.value)
            }
            onInputClear={(): void => setAssignedUserId(null)}
            callback={(change?: SuggestionResponse | null): void =>
              setAssignedUserId(change?.searchEntityId ?? null)
            }
          />
          {isValidationVisible &&
            Boolean(validationMessages?.assignedUserId) && (
            <small className='reason-invalid'>
              {validationMessages?.assignedUserId}
            </small>
          )}
        </div>
        <div className={styles.surveillanceEscalatePopupHighImportance}>
          <Checkbox
            inputId='surveillance-escalate-high-importance'
            checked={isHighImportance}
            onChange={e => setIsHighImportance(Boolean(e.checked))}
          />
          <label htmlFor='surveillance-escalate-high-importance'>
            Mark as high importance
          </label>
        </div>
        <div className={styles.surveillanceEscalatePopupSubject}>
          <label>Subject</label>
          <InputText
            id='surveillance-escalate-subject'
            placeholder='Please add an optional subject'
            onFocus={(e): void => e.target.select()}
            value={subject ?? ''}
            onChange={(e): void => setSubject(e.target.value)}
          />
        </div>
        <div className={styles.surveillanceEscalatePopupNote}>
          <label>Note</label>
          <InputTextarea
            id='escalate-notes'
            autoResize
            placeholder='Please add an optional note/comment'
            onFocus={(e): void => e.target.select()}
            rows={5}
            value={notes ?? ''}
            onChange={(e): void => setNotes(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
});

EscalatePopup.displayName = 'EscalatePopup';

export { EscalatePopup };
export default EscalatePopup;
