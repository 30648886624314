import { ReactNode } from 'react';
import { ProgressBar } from 'primereact/progressbar';

import { getValueByPath } from 'helpers/Utils/misc';

import styles from './ProcessedCell.module.scss';

const ProcessedCell = <T,>(data: T, config: { field: string }): ReactNode => {
  const { field } = config;
  const [processedField, fromField] = field.split(',');
  const processed = getValueByPath<T>(data, processedField);
  const from = getValueByPath<T>(data, fromField);

  return (
    <div className={styles.surveillanceEscalationsProcessed}>
      <div className={styles.surveillanceEscalationsProcessedCell}>
        <ProgressBar
          className={styles.surveillanceEscalationsProcessedCellProgressBar}
          showValue={false}
          value={(+processed / +from) * 100}
        />
        <div className={styles.surveillanceEscalationsProcessedCellLabel}>
          {processed}/{from}
        </div>
      </div>
    </div>
  );
};

export default ProcessedCell;
export { ProcessedCell };
