import { Dispatch, SetStateAction, useReducer } from 'react';

import {
  SurveillanceEntityStatus,
  SurveillanceModeEnum,
} from '../Models/Enums';
import { SurveillanceGridItem } from '../Models/ReportsResponse';

import { parsePropsToDateTime } from 'helpers/Utils/misc';

type ResultsReducerAction = {
  payload: {
    resultsMode?: SurveillanceModeEnum;
    status?: SurveillanceEntityStatus;
    results:
      | SurveillanceGridItem[]
      | ((currentResults: SurveillanceGridItem[]) => SurveillanceGridItem[]);
  };
};
type ResultsReducer = (
  state: SurveillanceGridItem[],
  action: ResultsReducerAction
) => SurveillanceGridItem[];

const isResultEqual = (
  a: SurveillanceGridItem,
  b: SurveillanceGridItem
): boolean => a.id === b.id && a.partitionKey === b.partitionKey;

const useResultsReducer = (
  initial: SurveillanceGridItem[],
  setResultSelected: Dispatch<SetStateAction<SurveillanceGridItem | null>>,
  setSelectedItems: Dispatch<SetStateAction<SurveillanceGridItem[]>>,
  setResultsCount: Dispatch<SetStateAction<number>>,
  onResultDeleted: () => void
): [SurveillanceGridItem[], Dispatch<ResultsReducerAction>] => {
  const reducer: ResultsReducer = (
    currentResults,
    { payload: { resultsMode, status, results } }
  ) => {
    const updatedItems =
      typeof results === 'function' ? results(currentResults) : results;

    if (!resultsMode || !status) {
      return updatedItems;
    }

    const removeItems = (
      items: SurveillanceGridItem[]
    ): SurveillanceGridItem[] => {
      // Unselect if result has been removed
      setResultSelected(resultSelected =>
        resultSelected &&
        items.find(item => isResultEqual(resultSelected, item))
          ? null
          : resultSelected
      );
      // Unselect checked results that has been removed
      setSelectedItems(selectedItems =>
        selectedItems.filter(
          selectedItem => !items.find(item => isResultEqual(selectedItem, item))
        )
      );
      // Remove from results
      const updatedResults = currentResults.filter(
        result => !items.find(item => isResultEqual(result, item))
      );
      const resultsDiff = updatedResults.length - currentResults.length;

      if (resultsDiff < 0) {
        onResultDeleted();
      }
      setResultsCount(resultsCount => resultsCount + resultsDiff);

      return updatedResults;
    };
    const updateResults = (
      arr: SurveillanceGridItem[],
      items: SurveillanceGridItem[]
    ): SurveillanceGridItem[] => {
      const addItems = [];

      for (const item of items) {
        const indexResults: number = arr.findIndex(
          d => d.id === item.id && d.partitionKey === item.partitionKey
        );
        // If it's not already in results -> add itd
        if (indexResults === -1) {
          addItems.push(parsePropsToDateTime(item, ['startTime', 'stopTime']));
          // Or update if it's in the list (escalated -> activeWithComments)
        } else if (indexResults > -1) {
          arr.splice(
            indexResults,
            1,
            parsePropsToDateTime(item, ['startTime', 'stopTime'])
          );
        }
      }

      setResultsCount(resultsCount => resultsCount + addItems.length);

      return [...arr, ...addItems].sort(
        (a, b) => b.startTime.toMillis() - a.startTime.toMillis()
      );
    };

    switch(resultsMode) {
      case SurveillanceModeEnum.Results: {
        // IF Results Tab (and status is changed to reviewed) THEN remove items
        if (status === SurveillanceEntityStatus.Reviewed) {
          return removeItems(updatedItems);
        } else if (
          // IF Results Tab (and status is changed to active or active with comments or escalated) THEN add/update items
          status === SurveillanceEntityStatus.Active ||
          status === SurveillanceEntityStatus.ActiveWithComments ||
          status === SurveillanceEntityStatus.Escalated
        ) {
          return updateResults(currentResults, updatedItems);
        }
        break;
      }
      case SurveillanceModeEnum.Reviewed: {
        // IF Reviewed Tab (and status is changed to active or active with comments) THEN remove items
        if (
          status === SurveillanceEntityStatus.Active ||
          status === SurveillanceEntityStatus.ActiveWithComments
        ) {
          return removeItems(updatedItems);
        }
        // IF Reviewed Tab (and status is changed to reviewed) THEN add/update items
        else if (status === SurveillanceEntityStatus.Reviewed) {
          return updateResults(currentResults, updatedItems);
        }
        break;
      }
      case SurveillanceModeEnum.Escalated: {
        // IF Escalated popup (Supervisor View) THEN add/update items
        return updateResults(currentResults, updatedItems);
      }
    }

    return updatedItems;
  };

  const [reducerValue, dispatcher] = useReducer(reducer, initial);

  return [reducerValue, dispatcher];
};

export { useResultsReducer };
