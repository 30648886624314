import Joi from 'joi';

import { TIME_REGEX } from 'helpers/Utils/string';
import { getValueCollection } from 'helpers/Utils/enum';

import { SubscriptionDataset } from './Enums';
import { FIELDS_CONFIG } from './Consts';

const VALIDATION_MESSAGES = {
  'string.pattern.base': 'Invalid format. Please, use HH:MM format',
  'string.empty': 'Required field',
  'any.required': 'Required field',
  'array.min': 'At least {#limit} item(s) must be selected',
  'string.email': 'Invalid format'
};

const { callsPerMinute, maxHistoryDays, maxProducts, numberOfDays, regions, resources, subscriptions, timeWindow } = FIELDS_CONFIG;

const generateBaseSchema = (dataSet: SubscriptionDataset): Joi.ObjectSchema => {
  return Joi.object({
    dataSet: Joi.string().allow(...getValueCollection(SubscriptionDataset)),
    subscriptionName: Joi.string().required(),
    clientName: Joi.string().required(),
    clientEmail: Joi.string().email({ tlds: { allow: false } }).required(),
    startDate: Joi.object().required(),
    isTrial: Joi.boolean().required(),
    marketClose: Joi.string().regex(TIME_REGEX).required(),
    numberOfDays: Joi.number().required().min(numberOfDays[dataSet]!.min!),
    timeWindow: Joi.array().items(...timeWindow[dataSet]!.options!).min(timeWindow[dataSet]!.min!),
    callsPerMinute: Joi.number().min(callsPerMinute[dataSet]!.min!),
    resources: Joi.array().items(Joi.string()).min(resources[dataSet]!.min!),
  }).unknown(true).messages(VALIDATION_MESSAGES);
};

const getRegionsValidator = (dataSet: SubscriptionDataset): Joi.AlternativesSchema => {
  return Joi.when('subscriptions', {
    is: Joi.array().min(1),
    then: Joi.array().items(Joi.string()).min(regions[dataSet]!.min!),
    otherwise: Joi.optional()
  });
}

const rateGridsValidator = generateBaseSchema(SubscriptionDataset.RateGridsPackage)
  .concat(Joi.object({
    expiryDate: Joi.object().required(),
    maxProducts: Joi.number().required().min(maxProducts.RateGridsPackage!.min!).max(maxProducts.RateGridsPackage!.max!),
    maxHistoryDays: Joi.number().required().min(maxHistoryDays.RateGridsPackage!.min!),
    subscriptions: Joi.array().items(Joi.string().valid(...subscriptions.RateGridsPackage!.options!).insensitive()).min(subscriptions.RateGridsPackage!.min!),
    regions: getRegionsValidator(SubscriptionDataset.RateGridsPackage),
    groupable: Joi.boolean().required(),
  }));

const derivativesExchangesValidator = generateBaseSchema(SubscriptionDataset.DerivativesExchanges)
  .concat(Joi.object({
    expiryDate: Joi.object().required(),
    maxProducts: Joi.number().required().min(maxProducts.DerivativesExchanges!.min!).max(maxProducts.DerivativesExchanges!.max!),
    maxHistoryDays: Joi.number().required().min(maxHistoryDays.DerivativesExchanges!.min!),
    subscriptions: Joi.array().items(Joi.string().valid(...subscriptions.DerivativesExchanges!.options!).insensitive()).min(subscriptions.DerivativesExchanges!.min!),
    regions: getRegionsValidator(SubscriptionDataset.DerivativesExchanges)
  }));

export const getValidator = (dataSet: SubscriptionDataset): Joi.ObjectSchema | null => {
  switch (dataSet) {
    case SubscriptionDataset.RateGridsPackage:
      return rateGridsValidator;
    case SubscriptionDataset.DerivativesExchanges:
      return derivativesExchangesValidator;
    default:
      return null;
  }
};