/* eslint-disable no-unused-vars */
import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';
import type { ToastMessageRef } from 'components/ToastMessage';

import type {
  ResultsResponse,
  SurveillanceMedia,
} from 'modules/Surveillance/Models/ReportsResponse';

import type {
  SurveillanceEntityStatus,
  SurveillanceModeEnum,
} from '../../../Models/Enums';
import type { Reason } from '../../MarkAsReviewedPopup/Models/Enums';
import type { RequestSiblingMessagesDirection } from './Request';
import type { DateTime } from 'luxon';
import type { Dispatch, ReactNode, RefObject, SetStateAction } from 'react';

export enum DetailsTabs {
  Details = 'Details',
  Metadata = 'Metadata',
  Activity = 'Activity',
}

export enum MIMEType {
  application = 'application',
  audio = 'audio',
  example = 'example',
  font = 'font',
  image = 'image',
  model = 'model',
  text = 'text',
  video = 'video',
}

export enum AttachmentType {
  Audio = 'Audio',
  Image = 'Image',
  File = 'File',
}

export const AttachmentTypeMap: Partial<Record<MIMEType, AttachmentType>> = {
  [MIMEType.audio]: AttachmentType.Audio,
  [MIMEType.image]: AttachmentType.Image,
};

export type AttachmentItem<T = AttachmentType> = {
  type: T;
  source: string;
  fileName: string;
};

export interface AttachmentComponentProps extends AttachmentItem {}

export interface AttachmentSectionProps {
  className?: string;
  header?: string;
  data?: AttachmentComponentProps | AttachmentComponentProps[];
}

export type ContentComponentMessage = Pick<
  ResultsResponse,
  | 'id'
  | 'content'
  | 'company'
  | 'userName'
  | 'provider'
  | 'partitionKey'
  | 'isAttachment'
  | 'media'
  | 'startTime'
  | 'highlights'
  | 'counterParty'
  | 'number'
  | 'email'
  | 'groupName'
> & {
  versions: DetailsVersion[];
  isEditable?: boolean;
};

type ContentComponentMessageUpdateStatus = {
  assignedUserId: string;
  assignedUserName: string;
  batchId: string;
  status: SurveillanceEntityStatus;
  worksheetId: string;
};

export type ContentComponentMessageUpdate = {
  averageConfidence: number;
  companyName: string; // company from ContentComponentMessage / ResultsResponse
  date: string;
  duration: string;
  id: string;
  location: string;
  reportStates: ContentComponentMessageUpdateStatus[];
  transcriptionLink: string | null;
  userId: string;
  username: string; // userName from ContentComponentMessage / ResultsResponse
  versions: DetailsVersion[];
};

export type SelectedContentMessage = {
  id: string;
  partitionKey: string;
  parent?: SelectedContentMessage;
};

export type ContentComponentProps = {
  worksheetId: string | null | undefined;
  mainMessage: ResultsResponse;
  messagesPerPage: number;
  panelState: DetailsPanelState;
  className?: string;
  messages?: ContentComponentMessage[];
  selectedMessages?: ResultsResponse[];
  setSelectedMessages?: Dispatch<SetStateAction<ResultsResponse[]>>;
  selectedSiblingMessages?: SelectedContentMessage[];
  setSelectedSiblingMessages?: Dispatch<
    SetStateAction<SelectedContentMessage[]>
  >;
  hasNoNextMessages?: boolean | null;
  hasNoPrevMessages?: boolean | null;
  setMsgDirection?: Dispatch<
    SetStateAction<RequestSiblingMessagesDirection | null>
  >;
  setPanelState?: Dispatch<SetStateAction<DetailsPanelState>>;
  onTranscriptionState?: (id: string, state: TranscriptionState) => void;
  saveTranscriptionVersion?: (transcription: string) => Promise<void>;
  scrollExpandedDetailsRef?: RefObject<HTMLDivElement>;
  resultsMode?: SurveillanceModeEnum;
  isMessagesSelectable?: boolean;
};

export enum TranscriptionState {
  Edit = 'Edit',
  View = 'View',
}

export interface TranscriptionComponentProps {
  messageValue: ContentComponentMessage;
  worksheetId: string | null | undefined;
  isMain?: boolean;
  isSelectable?: boolean;
  isMessageSelected?: boolean;
  className?: string;
  onTranscriptionStateChanged?: (
    message: ContentComponentMessage,
    state: TranscriptionState
  ) => void;
  saveTranscriptionVersion?: (transcription: string) => Promise<void>;
  onSelectionChanged?: (state: boolean) => void;
}

export interface TranscriptionFooterProps {
  isDisabled: boolean;
  isEditing: boolean;
  onCancel: () => void;
  transcription?: DetailsVersion;
  onSave?: () => void;
  isEditable?: boolean;
}

export interface TranscriptionDetailsProps {
  messageValue: ContentComponentMessage;
}

export enum DetailsPanelState {
  collapsed = 'collapsed',
  expanded = 'expanded',
}

export interface RightPanelProps {
  activeWorksheetId: string | null | undefined;
  record: ResultsResponse;
  setRecordSelected: (result: ResultsResponse | null) => void;
  selectedMessages: ResultsResponse[];
  setSelectedMessages: Dispatch<SetStateAction<ResultsResponse[]>>;
  selectedContentMessages: SelectedContentMessage[];
  setSelectedContentMessages: Dispatch<
    SetStateAction<SelectedContentMessage[]>
  >;
  panelState: DetailsPanelState;
  setPanelState: Dispatch<SetStateAction<DetailsPanelState>>;
  searchRequestFields?: SearchField[];
  resultsMode?: SurveillanceModeEnum;
  isMessagesSelectable?: boolean;
}

export interface MetadataViewProps {
  record: ResultsResponse;
  className?: string;
  metadata?: Metadata;
  toastRef?: RefObject<ToastMessageRef>;
  topRibbon?: ReactNode;
}

export interface ActivityViewProps {
  activeWorksheetId: string | null | undefined;
  record: ResultsResponse;
  topRibbon?: ReactNode;
}

export interface DetailsComponentProps {
  companyName: string;
  details?: SurveillanceDetailsResponse;
  highlights?: HighlightsResultsResponse;
  className?: string;
  hidden?: boolean;
}

enum DetailsContentMedia {
  attachment = 'attachment',
  shoutdown = 'shoutdown',
  sms = 'sms',
  voice = 'voice',
}

export type DetailsVersion = {
  isOriginal: boolean;
  text: string;
  updatedAt: string;
  updatedById?: string;
  updatedByName?: string;
};

export type HighlightsResultsResponse = {
  content?: string[];
};

type DetailsContent = {
  username: string;
  participants: string;
  providerName: string;
  startTime: string;
  versions: DetailsVersion[];
  line?: string;
  media?: SurveillanceMedia;
  isAttachment?: boolean;
  highlights?: HighlightsResultsResponse;
};

export type Metadata = {
  'record id': string;
  'utc offset': string;
  group: string;
  'call status': string;
  'stop reason'?: string;
  region?: string;
  type?: DetailsContentMedia;
  email?: string;
  'counterpart country'?: string;
  'contact number'?: string;
  number?: string;
};

export type SurveillanceDetailsResponse = DetailsContent & {
  metadata: Metadata;
};

export type SurveillanceActivityResponse = {
  batchId: string;
  note: string;
  updatedAt: DateTime;
  updatedById: string;
  updatedByName: string;
  status?: SurveillanceEntityStatus;
  previousStatus?: SurveillanceEntityStatus;
  reason?: Reason | null;
  assignedUserId?: string;
  assignedUserName?: string;
};
